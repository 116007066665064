<template>
  <div class="app">
    <AppHeader fixed>
      <b-link class="navbar-brand" to="/">
        <img
          class="navbar-brand-full d-md-down-none"
          src="@/../public/img/brand/entirelogo.svg"
          width="89"
          height="25"
          alt="Carlbot logo"
        />
        <img
          class="navbar-brand-minimized"
          src="@/../public/img/brand/googleturtle.svg"
          width="30"
          height="30"
          alt="Carlbot logo"
        />
      </b-link>
      <b-navbar-nav class="d-md-down-none nav-title">
        <b-nav-item class="px-2" href="https://docs.carl.gg/">
          <i class="fas fa-book mr-1"></i>Docs
        </b-nav-item>
        <b-nav-item
          class="px-2"
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
        >
          <i class="fas fa-plus mr-1"></i>Invite
        </b-nav-item>
        <b-nav-item class="px-2" href="https://discord.gg/S2ZkBTnd8X">
          <i class="fab fa-discord mr-1"></i>Discord
        </b-nav-item>
        <b-nav-item class="px-2" href="https://www.patreon.com/carlbot">
          <img :src="imageUrl" style="width: 12px; height: auto; margin-bottom: 3px"/> Premium
        </b-nav-item>
        <b-nav-item class="px-2" to="/status">
          <i class="fas fa-heartbeat mr-1"></i>Status
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="d-lg-none nav-title nav-icons">
        <b-nav-item to="/">
          <i class="fas fa-home"></i>
        </b-nav-item>
        <b-nav-item href="https://docs.carl.gg/">
          <i class="fas fa-book"></i>
        </b-nav-item>
        <b-nav-item
          href="https://discord.com/oauth2/authorize?&client_id=235148962103951360&scope=applications.commands+bot&permissions=2088234230&response_type=code&redirect_uri=https://carl.gg/api/server_auth"
          exact
        >
          <i class="fas fa-plus"></i>
        </b-nav-item>
        <b-nav-item href="https://discord.gg/S2ZkBTnd8X" class="d-sm-down-none">
          <i class="fab fa-discord"></i>
        </b-nav-item>
        <b-nav-item href="https://www.patreon.com/carlbot">
          <i class="fab fa-patreon"></i>
        </b-nav-item>
        <b-nav-item to="/status">
          <i class="fas fa-heartbeat"></i>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <div v-if="logged_in">
          <DefaultHeaderDropdownAccnt />
        </div>
        <div v-else class="mr-2">
          <b-button
            style="
              background-color: #3a4149;
              border-color: #eee;
              border-width: 2px;
            "
            v-on:click="login"
            class="px-4 btn-pill dark"
            >Login</b-button
          >
        </div>
      </b-navbar-nav>
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body background">
      <main class="main" style="margin-left: 0px">
        <div>
          <br />
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { Header as AppHeader } from "@coreui/vue";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";

export default {
  name: "DefaultContainer",
  components: {
    AppHeader,
    DefaultHeaderDropdownAccnt
  },
  data() {
    return {
      discord_user: {},
      imageUrl: 'https://i.imgur.com/OOh0shV.png',
    };
  },
  methods: {
    login() {
      window.location.href = window.location.origin + "/api/login";
    },
    updateAuthInfo(e) {
      if (e.data.discriminator != undefined) {
        this.$store.commit("login", e.data);
      }
      window.removeEventListener("message", this.updateAuthInfo);

      this.$router.push("/servers");
    }
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    },
    logged_in() {
      return Object.keys(this.$store.state.discord_user).length !== 0;
    }
  }
};
</script>

<style>
@media screen and (min-width: 1280px) {
  .background {
    background-image: url("~@/../public/img/bgheader.png");
    min-height: 85vh !important;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
}

@media screen and (min-width: 780px) and (max-width: 1280px) {
  .background {
    background-image: url("~@/../public/img/bgheader-mid.png");
    min-height: 85vh !important;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
}
@media screen and (max-width: 780px) {
  .background {
    background-image: url("~@/../public/img/bgheader.png");
    min-height: 85vh !important;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  }
  .main::before {
    display: none;
  }
}
</style>

<style scoped>
.nav-title .nav-link {
  font-family: "roboto";
  color: #fff;
}
.nav-icons i {
  font-size: 1.075rem;
}
</style>
